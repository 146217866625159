<script lang="ts" setup>
const props = defineProps({
  open: {
    type: Boolean,
    default: false
  }
});

const isOpen = ref(props.open);
</script>
<template>
  <div class="accordion" @click="isOpen = !isOpen">
    <div class="accordion-head">
      <div>
        <slot name="head"/>
      </div>
    </div>
    <div class="accordion-body" v-if="isOpen">
      <div>
        <slot name="body"/>
      </div>
    </div>
  </div>
</template>